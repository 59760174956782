import {
  EmployeeConnect,
  GatePass,
  InspectionPermission,
  PettyCashPermission,
  PPMPermission,
} from './../../modules/user/models/permission.models';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Role } from '../models/common.models';

export class PermissionEvaluator extends AuthService {
  private static instance: PermissionEvaluator;
  userPermission: UserPermissions;
  entityProfile: EntityProfilePermission;
  private constructor() {
    super();
    this.userPermission = this.getCurrentUser()['permission'];
    this.entityProfile = this.getEntityProfile();
  }

  public static getInstance(): PermissionEvaluator {
    if (!PermissionEvaluator.instance) {
      this.instance = new PermissionEvaluator();
    }
    return PermissionEvaluator.instance;
  }

  public destroy() {
    PermissionEvaluator.instance = null;
  }

  isHaveHKPermission(): boolean {
    return this.isEntityHaveHKPermission() && this.isUserHaveHKPermission();
  }
  // house keeping permission
  isUserHaveHKPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.housekeeping &&
      this.userPermission.housekeeping.enabled
    );
  }

  isEntityHaveHKPermission(): boolean {
    return (
      this.entityProfile &&
      this.entityProfile.data.housekeeping &&
      this.entityProfile.data.housekeeping.enabled
    );
  }

  // to check Service permissions(work-order)
  isEntityHaveServicePermission(): boolean {
    return (
      this.entityProfile &&
      this.entityProfile.data.service &&
      this.entityProfile.data.service.enabled
    );
  }

  isUsetHaveGatePassPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.gatePassPermission &&
      this.userPermission.gatePassPermission.enabled
    );
  }

  isUsetHavePettyCashPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.pettyCashPermission &&
      this.userPermission.pettyCashPermission.enabled
    );
  }

  isUserServiceHKPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.service &&
      this.userPermission.service.enabled
    );
  }

  isRequestManager(): boolean {
    return (
      this.userPermission &&
      this.userPermission.service &&
      this.userPermission.service.enabled &&
      this.userPermission.service.requestManager
    );
  }
  // review permission
  isEntityHaveReviewPermission(): boolean {
    return (
      this.entityProfile &&
      this.entityProfile.data.review &&
      this.entityProfile.data.review.enabled
    );
  }

  isUserHaveReviewPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.review &&
      this.userPermission.review.enabled
    );
  }

  // Guest Experience permission
  isEntityHaveFeedbackPermission(): boolean {
    return (
      this.entityProfile &&
      this.entityProfile.data.feedback &&
      this.entityProfile.data.feedback.enabled
    );
  }

  isUserHaveFeedbackPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.feedback &&
      this.userPermission.feedback.enabled
    );
  }

  // Guest Management Permission
  isEntityHaveGuestEntryPermission(): boolean {
    return (
      this.entityProfile &&
      this.entityProfile.data.guestEntry &&
      this.entityProfile.data.guestEntry.enabled
    );
  }

  isUserHaveGuestEntryPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.guestEntry &&
      this.userPermission.guestEntry.enabled
    );
  }

  isUserHaveInspectionPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.cix &&
      this.userPermission.cix.enabled
    );
  }

  isUserHavePPMPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.ppm &&
      this.userPermission.ppm.enabled
    );
  }

  isUserHaveIRDPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.irdPermission &&
      this.userPermission.irdPermission.enabled
    );
  }

  isUserHaveChecklistCreatePermission(): boolean {
    return (
      this.isUserHaveInspectionPermission() &&
      this.userPermission.cix?.canCreateChecklist
    );
  }

  isUserHaveChecklistUpdatePermission(): boolean {
    return (
      this.isUserHaveInspectionPermission() &&
      this.userPermission.cix?.canUpdateChecklist
    );
  }

  // Marketing Permission
  isEntityHaveMarketingPermission(): boolean {
    return (
      this.entityProfile &&
      this.entityProfile.data.marketing &&
      this.entityProfile.data.marketing.enabled
    );
  }

  isUserHaveMarketingPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.marketing &&
      this.userPermission.marketing.enabled
    );
  }

  // CRM permission
  isUserHaveCrmPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.crm &&
      this.userPermission.crm.enabled
    );
  }

  isUserHaveLoyaltyPermission(): boolean {
    return (
      this.isUserHaveCrmPermission() && this.userPermission.crm.manageLoyalty
    );
  }

  isUserHaveSegmentsPermission(): boolean {
    return (
      this.isUserHaveCrmPermission() && this.userPermission.crm.manageSegments
    );
  }

  isUserHaveLoyaltyReportPermission(): boolean {
    return (
      this.isUserHaveReportPermission() &&
      this.userPermission.report.loyalty &&
      this.userPermission.report.loyalty.enabled
    );
  }

  // template management permission
  isUserHaveTemplatePermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.template &&
      this.userPermission.template.enabled
    );
  }

  isUserHaveDocumentPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.document &&
      this.userPermission.document.enabled
    );
  }

  isUserHaveTaskPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.task &&
      this.userPermission.task.enabled
    );
  }

  isUserHaveKraPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.kra &&
      this.userPermission.kra.enabled
    );
  }

  isUserHaveAuditPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.audit &&
      this.userPermission.audit.enabled
    );
  }

  isUserHaveMomPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.mom &&
      this.userPermission.mom.enabled
    );
  }

  isUserHaveIncidentPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.incidence &&
      this.userPermission.incidence.enabled
    );
  }

  isUserHaveBudgetPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.budget &&
      this.userPermission.budget.enabled
    );
  }

  isUserHaveBudgetCapexPermission(): boolean {
    return (
      this.isUserHaveBudgetPermission() && this.userPermission.budget.capex
    );
  }

  isUserHaveBudgetOperationalPermission(): boolean {
    return (
      this.isUserHaveBudgetPermission() &&
      this.userPermission.budget.operational
    );
  }

  isUserHaveBudgetPeoplePermission(): boolean {
    return (
      this.isUserHaveBudgetPermission() && this.userPermission.budget.people
    );
  }

  isBudgetEnabled(type: string): boolean {
    // Check if the key exists in the object and return its value
    return this.userPermission &&
      this.userPermission.budget &&
      this.userPermission.budget?.hasOwnProperty(type)
      ? this.userPermission.budget[
          type as keyof typeof this.userPermission.budget
        ]
      : false;
    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * Checks if the current user has permission for budget projects.
     *
     * @returns {boolean} True if the user has budget project permission,
     *                    false otherwise.
     */

    /******  093cb3d4-c425-4e0a-a2b1-71472f9d657f  *******/
  }

  isUserHaveBudgetProjectPermission(): boolean {
    return (
      this.isUserHaveBudgetPermission() && this.userPermission.budget.project
    );
  }

  isUserHaveSchedulerPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.scheduler &&
      this.userPermission.scheduler.enabled
    );
  }

  isUserHaveEmployeeConnectPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.employeeConnect &&
      this.userPermission.employeeConnect.enabled
    );
  }

  isUserHaveLogPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.log &&
      this.userPermission.log.enabled
    );
  }

  isUserHaveReportPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.report &&
      this.userPermission.report.enabled
    );
  }

  isUserHaveSurveyPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.survey &&
      this.userPermission.survey.enabled
    );
  }

  isUserHaveUserMnagementPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.user &&
      this.userPermission.user.enabled
    );
  }

  isUserHaveSettingsPermission(): boolean {
    return (
      this.userPermission &&
      this.userPermission.settings &&
      this.userPermission.settings.enabled
    );
  }

  isUserHaveWorkOrderReportPermission(): boolean {
    return (
      this.isUserHaveReportPermission() &&
      this.userPermission.report.workOrder &&
      this.userPermission.report.workOrder.enabled
    );
  }

  isUserHaveFeedbackReportPermission(): boolean {
    return (
      this.isUserHaveReportPermission() &&
      this.userPermission.report.feedback &&
      this.userPermission.report.feedback.enabled
    );
  }

  isUserHaveSurveyReportPermission(): boolean {
    return (
      this.isUserHaveReportPermission() &&
      this.userPermission.report.survey &&
      this.userPermission.report.survey.enabled
    );
  }

  isUserHaveGuestEntryReportPermission(): boolean {
    return (
      this.isUserHaveReportPermission() &&
      this.userPermission.report.guestEntry &&
      this.userPermission.report.guestEntry.enabled
    );
  }

  isUserHaveCommunicationPermission(): boolean {
    return (
      this.isUserHaveReportPermission() &&
      this.userPermission.report.Communication &&
      this.userPermission.report.Communication.enabled
    );
  }

  isUserHaveManageDutyLogPermission(): boolean {
    return (
      this.isUserHaveLogPermission() && this.userPermission.log.manageDutyLog
    );
  }

  isUserHaveManageReadingPermission(): boolean {
    return (
      this.isUserHaveLogPermission() && this.userPermission.log.manageReading
    );
  }

  isUserHaveManageChemicalsPermission(): boolean {
    return (
      this.isUserHaveLogPermission() && this.userPermission.log.manageChemicals
    );
  }

  isUserHaveManageTransactionsPermission(): boolean {
    return (
      this.isUserHaveLogPermission() &&
      this.userPermission.log.transactionLog.enabled
    );
  }

  isUserHaveManageWorkLogPermission(): boolean {
    return (
      this.isUserHaveLogPermission() &&
      this.userPermission?.log?.workLog?.enabled &&
      (this.userPermission?.log?.workLog?.canCreate ||
        this.userPermission?.log?.workLog?.canView ||
        this.userPermission?.log?.workLog?.canApprove)
    );
  }

  isUserHaveManageActivityPermission(): boolean {
    return (
      this.isUserHaveLogPermission() && this.userPermission.log.manageActivity
    );
  }

  isUserHaveManageIncidentsPermission(): boolean {
    return (
      this.isUserHaveLogPermission() && this.userPermission.log.manageIncidents
    );
  }

  isUserHaveManageLostAndFoundPermission(): boolean {
    return (
      this.isUserHaveLogPermission() &&
      this.userPermission.log.manageLostAndFound
    );
  }

  isUserHaveManageLuggagePermission(): boolean {
    return (
      this.isUserHaveLogPermission() && this.userPermission.log.manageLuggage
    );
  }

  isUserHaveManageContractorsPermission(): boolean {
    return (
      this.isUserHaveLogPermission() &&
      this.userPermission.log.manageContractors
    );
  }

  isUserHaveManageFoPermission(): boolean {
    return (
      this.isUserHaveLogPermission() &&
      this.userPermission.log.transactionLog != undefined &&
      this.userPermission.log.transactionLog.enabled &&
      this.userPermission.log.transactionLog.manageFo
    );
  }

  isUserHaveManageFnBPermission(): boolean {
    return (
      this.isUserHaveLogPermission() &&
      this.userPermission.log.transactionLog != undefined &&
      this.userPermission.log.transactionLog.manageFnB
    );
  }

  isUserHaveManagefNbProductionPermission(): boolean {
    return (
      this.isUserHaveLogPermission() &&
      this.userPermission.log.transactionLog != undefined &&
      this.userPermission.log.transactionLog.manageFnBProduction
    );
  }

  isUserHaveHkLogPermission(): boolean {
    return (
      this.isUserHaveLogPermission() &&
      this.userPermission.log.transactionLog != undefined &&
      this.userPermission.log.transactionLog.manageHk
    );
  }

  isCorporate() {
    return this.getCurrentUser()?.role != Role.PROPERTY_USER;
  }

  isUserHaveCollaborationPermission(): boolean {
    return (
      this.isUserHaveSchedulerPermission() ||
      this.isUserHaveTaskPermission() ||
      this.isUserHaveMomPermission()
    );
  }

  isUserHaveGuestManagementPermission(): boolean {
    return (
      this.isUserHaveGuestEntryPermission() ||
      this.isUserHaveCrmPermission() ||
      this.isUserHaveLoyaltyPermission() ||
      this.isUserHaveSegmentsPermission() ||
      this.isUserHaveMarketingPermission() ||
      this.isUserHaveLoyaltyReportPermission()
    );
  }

  isUserHaveOperationsPermission(): boolean {
    return (
      this.isUsetHaveGatePassPermission() ||
      this.isUserServiceHKPermission() ||
      this.isUserHaveHKPermission() ||
      this.isUserHaveLogPermission() ||
      this.isUserHaveEmployeeConnectPermission() ||
      this.isUserHaveKraPermission() ||
      this.isUserHaveIRDPermission()
    );
  }

  isUserHaveQualitypermission(): boolean {
    return (
      this.isUserHaveIncidentPermission() ||
      this.isUserHaveAuditPermission() ||
      this.isUserHaveInspectionPermission() ||
      this.isUserHaveRegulatoryCompliancesPermission()
    );
  }

  isUserHaveMainSettingsPermission(): boolean {
    return (
      this.isUserHaveTemplatePermission() ||
      this.isUserHaveSurveyPermission() ||
      this.isUserHaveSettingsPermission()
    );
  }

  isUserHaveRegulatoryCompliancesPermission(): boolean {
    return (
      this.userPermission?.quality?.enabled == true &&
      this.userPermission?.quality?.regulatoryCompliance
    );
  }
}

export class UserPermissions {
  crm: Crm;
  dashboard: Dashboard;
  entity: Entity;
  feedback: Feedback;
  guestEntry: GuestEntry;
  housekeeping: Housekeeping;
  issueRequest: IssueRequest;
  marketing: Marketing;
  pulse: Pulse;
  report: Report;
  service: Service;
  settings: Settings;
  survey: UserSurvey;
  tableReservation: TableReservation;
  template: Template;
  tripAdvisor: TripAdvisor;
  user: User;
  review: Review;
  document: Document;
  task: Task;
  kra: Kra;
  audit: Audit;
  mom: Mom;
  budget: Budget;
  scheduler: Scheduler;
  log: Log;
  incidence: Incidents;
  gatePassPermission: Gatepass;
  pettyCashPermission: PettyCash;
  employeeConnect: EmployeeConnect;
  cix: InspectionPermission;
  ppm: PPMPermission;
  quality: RegulatoryCompliancesPermission;
  irdPermission : IRDMenuPermission;
}

export class RegulatoryCompliancesPermission {
  enabled: boolean = false;
  regulatoryCompliance: boolean = false;
}

export class IRDMenuPermission {
  enabled: boolean = false;
}

export class Gatepass {
  enabled: boolean;
}

export class PettyCash {
  enabled: boolean;
  canSettle: true;
}

export class Crm {
  enabled: boolean;
  manageCRM: boolean;
  manageLoyalty: boolean;
  manageSegments: boolean;
}
export class Dashboard {
  enabled: boolean;
  dailyStatus: boolean;
}
export class Entity {
  enabled: boolean;
  manage: boolean;
}
export class Feedback {
  enabled: boolean;
  canAddNotes: boolean;
  canArchive: boolean;
}
export class GuestEntry {
  enabled: boolean;
  manageGuestEntry: boolean;
}
export class Housekeeping {
  enabled: boolean;
}
export class IssueRequest {
  enabled: boolean;
  manageRequest: boolean;
  requestManager: boolean;
}
export class Marketing {
  enabled: boolean;
}
export class Pulse {
  enabled: boolean;
}
export class UserSurvey {
  enabled: boolean;
}
export class Report {
  enabled: boolean;
  Communication: Communication;
  feedback: FeedbackReport;
  workOrder: WorkOrderReport;
  survey: SurveyReport;
  guestEntry: GuestEntryReport;
  loyalty: Loyalty;
}

export class Loyalty {
  enabled: boolean;
}

export class GuestEntryReport {
  enabled: boolean;
  dailySummary: boolean;
  performanceSummary: boolean;
  viewDetailsInSummary: boolean;
}
export class SurveyReport {
  enabled: boolean;
  dailySummary: boolean;
  performanceSummary: boolean;
  viewDetailsInSummary: boolean;
}
export class FeedbackReport {
  enabled: boolean;
  dailySummary: boolean;
  performanceSummary: boolean;
  feedbackRatingSummary: boolean;
  questionSummary: boolean;
  rootCauseSummary: boolean;
  viewDetailsInSummary: boolean;
}

export class WorkOrderReport {
  enabled: boolean;
  dailySummary: boolean;
  performanceSummary: boolean;
  viewDetailsInSummary: boolean;
}
export class Communication {
  enabled: boolean;
}
export class Service {
  enabled: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canChat: boolean;
  canClose: boolean;
  requestManager: boolean;
  managePreference: boolean;
}
export class Settings {
  enabled: boolean;
  deviceManagementEnabled: boolean;
  customerEnabled: boolean;
  manageAccount: boolean;
}
export class TableReservation {
  enabled: boolean;
  manageTableReservation: boolean;
}
export class Template {
  enabled: boolean;
  manageEmail: boolean;
  manageSMS: boolean;
  manageSurvey: boolean;
  manageSurveyCategory: boolean;
  manageTA: boolean;
  manageWhatsApp: boolean;
  viewEmail: boolean;
  viewSMS: boolean;
  viewSurvey: boolean;
  viewSurveyCategory: boolean;
  viewTA: boolean;
  viewWhatsApp: boolean;
}
export class TripAdvisor {
  enabled: boolean;
  manageTA: boolean;
}
export class User {
  enabled: boolean;
  canEdit: boolean;
  canManage: boolean;
}

export class EntityProfilePermission {
  data: EntityProfileInfo;
}

export class EntityProfileInfo {
  analytics: Analytics;
  feedback: Feedbacks;
  googleReview: GoogleReview;
  guestEntry: ProfileGuestEntry;
  housekeeping: Housekeeping;
  marketing: Marketing;
  review: Review;
  service: EntityService;
  survey: Survey;
  tableReservation: ProfileTableReservation;
  tripAdvisor: TripAdvisor;
}
export class Feedbacks {
  enabled: boolean;
  type: string;
  limit: number;
}
export class EntityService {
  enabled: boolean;
  type: string;
}
export class Survey {
  enabled: boolean;
  type: string;
  limit: number;
}
export class Analytics {
  enabled: boolean;
}
export class GoogleReview {
  enabled: boolean;
}
export class ProfileGuestEntry {
  enabled: boolean;
}
export class Review {
  enabled: boolean;
}
export class ProfileTableReservation {
  enabled: boolean;
}
export class ProfileTripAdvisor {
  enabled: boolean;
}

export class Document {
  enabled: boolean;
}
export class Task {
  enabled: boolean;
}
export class Kra {
  enabled: boolean;
}
export class Audit {
  enabled: boolean;
}
export class Mom {
  enabled: boolean;
}

export class Incidents {
  enabled: boolean;
}

export class Budget {
  enabled: boolean;
  capex: boolean;
  operational: boolean;
  people: boolean;
  project: boolean;
}
export class Scheduler {
  enabled: boolean;
}
export class Log {
  enabled: boolean;
  manageDutyLog: boolean;
  manageReading: boolean;
  manageChemicals: boolean;
  transactionLog: TransactionLog;
  manageActivity: boolean;
  manageIncidents: boolean;
  manageContractors: boolean;
  manageLostAndFound: boolean;
  manageLuggage: boolean;
  workLog: WorkLog;
}

export class WorkLog {
  enabled: boolean;
  canCreate: boolean;
  canView: boolean;
  canApprove: boolean;
}

export class TransactionLog {
  enabled: boolean;
  manageFnB: boolean;
  manageFo: boolean;
  manageFnBProduction: boolean;
  manageHk: boolean;
}
