<div class=" layout-fluid">
    <div class="page" style="overflow-x: hidden;">
        <!-- Sidebar -->
        <app-navigation *ngIf="showSideNav()"></app-navigation>
        <div *ngIf="!showSideNav()" class="nav-btn">
            <a class="btn bg1 pad1" (click)="unHideNav()" role="button" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 6l16 0"></path>
                    <path d="M4 12l16 0"></path>
                    <path d="M4 18l16 0"></path>
                </svg>
            </a>
        </div>
        <div class="page-wrapper" [ngClass]="{'page-wrapper-16': showSideNav(), 'page-wrapper-5': !showSideNav()}">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>