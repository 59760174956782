<div class="filter">
    <div style="width: 100%;" ngbDropdown class="d-inline-block" #filter="ngbDropdown">
        <button  class="form-select newfont display_button" ngbDropdownToggle style="overflow-x: hidden;">
            {{((Selected_Filter==='CUSTOM')&&customeRanges)?(customeRanges?.startTime | date) +'
            -'+(customeRanges?.endTime | date):getDisplayString(Selected_Filter)}}<span *ngIf="!Selected_Filter">Select
                date</span></button>
        <div ngbDropdownMenu   aria-labelledby="Filter" style="max-height: 500px;
        overflow: auto;background: white;">
            <div style="padding:10px;padding-bottom: 0px;display: flex;">
                <div style="width: 200px;">
                    <span *ngFor="let item of options">
                        <button class="dropdown_button" [ngClass]="{activebtn:Selected_Filter === item}"
                            (click)="Selected_Filter=item;rangeSelected(item);">{{getDisplayString(item)}}</button>
                    </span>
                </div>
                <div style="max-width: 500px;">
                    <div *ngIf="Selected_Filter == 'CUSTOM'">
                        <div class="row">
                            <mat-calendar class="col-xs-12 col-md-12 col-lg-6"
                                [(selected)]="Selected_CusFrom"></mat-calendar>
                            <mat-calendar class="col-xs-12 col-md-12 col-lg-6"
                                [(selected)]="Selected_CusTo"></mat-calendar>
                        </div>
                        <button class="dropdown_button" (click)="ChooseCusDate();">Apply</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>